/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/react";

const wrapperStyle = css`
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  p {
    margin: 8px 0;
  }
`;

export function Prologue() {
  return (
    <div css={wrapperStyle}>
      <p>ーー謎解き好きな私の元に届いたのは、一通の手紙だった。</p>
      <p>手紙の差出人は、私が大学時代に所属していた謎解きサークルの友人だ。</p>
      <p>
        中を見ると、「お前の誕生日を祝いたいから、俺の所有するビルまで来てくれ。」という旨の文章とともに、日時、ビルの住所と地図、行くべきフロアや部屋が記されている。
      </p>
      <p>
        スケジュール帳を見ると、指定された日時には何も予定が入っていなかった。私は彼の誘いに乗ることにした。
      </p>
      <br />
      <p>何事もなく時は流れ、その日が訪れた。</p>
      <br />
      <p>
        地図を頼りにビルまで行き、エレベーターに乗って目的のフロアのボタンを押す。
      </p>
      <p>その時だった。</p>
      <p>上昇するはずのエレベーターは、突然下降し始めた。</p>
      <p>「やられた…」</p>
      <p>
        階数表示が「B1F」から変わらないエレベーターの中で、
        私は小さな声で呟いた。
      </p>
      <p>視線を落とすと、扉のディスプレイに問題が表示されているのが見えた。</p>
      <p>どうやら、この問題を解いていけば脱出できるようだ。</p>
      <p>私は、この手荒な誕生日サプライズに挑むことにした。</p>
    </div>
  );
}
